import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContextProvider from './context/contextProvider';
import Header from './components/Header/Header';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import JoinUs from './pages/JoinUs';
import Services from './pages/Services';
import CookieConsent from './components/Cookies/CookieConsent';
// Global styles
import './assets/vendors/animate.css';
import './assets/vendors/custom-animate.css';
import './assets/vendors/flaticon.css';
import './assets/vendors/fontawesome-all.css';
import './assets/vendors/linoor-icons.css';
import './assets/vendors/reey-font.css';
import './styles/style.css';
import './styles/hover.css';
import './styles/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import 'tiny-slider/dist/tiny-slider.css';
import DynamicTitle from './utils/dynamicTitles';
import DataPolicy from './pages/DataPolicy';
import LegalNotice from './pages/LegalNotice';
import CaptivePage from './pages/Captive';

const App = () => {
  return (
      <Router>
        <ContextProvider>
        <DynamicTitle />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/join-us" element={<JoinUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/data-policy" element={<DataPolicy />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
          <Route path="/captive" element={<CaptivePage />} />


        </Routes>
        <CookieConsent />
        </ContextProvider>

      </Router>
  );
};

export default App;
