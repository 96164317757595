import { Link } from "react-router-dom";
import React, { forwardRef, useState } from "react";
import { Image } from "react-bootstrap";

const SingleTeam = ({ team = {}, className = "", isBoardMember = false, index = -1 }, ref) => {
  const { image, name, designation, socials, description } = team;

  const [isHovered, setIsHovered] = useState(false);

  const styles = {
    teamBlock: {
      position: 'relative',
      marginBottom: '30px',
    },
    innerBox: {
      position: 'relative',
      textAlign: 'center',
    },
    imageBox: {
      position: 'relative',
      display: 'block',
      overflow: 'hidden',
    },
    image: {
      display: "block",
      width: "100%",
      filter:
        isBoardMember 
          ? "grayscale(100%)"
          : isHovered
          ? "grayscale(100%)"
          : "grayscale(0%)",
      transition: "all 500ms ease",
      borderRadius: "7px",
    },
    socialLinks: {
      position: 'absolute',
      left: '150px',
      right: '0',
      bottom: '-9px',
      textAlign: 'center',
      opacity: isHovered ? '1' : '0',
      visibility: isHovered ? 'visible' : 'hidden',
      transition: 'opacity 0.3s ease, visibility 0.3s ease',
      zIndex: '10',
    },
    socialLinkItem: {
      display: 'inline-block',
      margin: '0 5px',
    },
    socialLinkAnchor: {
      display: 'inline-block',
      width: '44px',
      height: '44px',
      lineHeight: '44px',
      background: '#ffffff',
      color: 'var(--thm-black)',
      borderRadius: '50%',
      fontSize: '16px',
      transition: 'all 500ms ease',
    },
    description: {
      position: 'absolute',
      left: '0',
      right: '0',
      bottom: '0px',
      textAlign: 'center',
      fontSize: '14px',
      color: 'white',
      background: 'rgba(0, 0, 0, 0.7)',
      padding: '10px',
      paddingBottom:"19px",
      borderRadius: '5px',
      opacity: isHovered ? '1' : '0',
      visibility: isHovered ? 'visible' : 'hidden',
      transition: 'opacity 0.3s ease, visibility 0.3s ease',
      zIndex: '5',  
      whiteSpace: 'normal', 
    },
    lowerBox: {
      position: 'relative',
      display: 'block',
      paddingTop: '30px',
    },
    designation: {
      fontSize: '16px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      lineHeight: '1.2em',
      fontWeight: '400',
      paddingTop: '5px',
    },
    name: {
      fontSize: '24px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      lineHeight: '1.05em',
      fontWeight: '400',
    },
    nameLink: {
      color: 'var(--thm-black)',
      textDecoration: 'none',
    },
    nameLinkHover: {
      textDecoration: 'underline',
    },
    description2: {
      width: "75%",
      margin: "0 auto", 
      display: "flex",
      alignItems: "center", 
      justifyContent: "center", 
      textAlign: "center", 
      height: "100%", 
    },
    
  };
  

  return (
    <div
      ref={ref}
      className={`team-block ${className}`}
      style={styles.teamBlock}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="inner-box" style={styles.innerBox}>
        <div className="image-box" style={styles.imageBox}>
            <Image
              src={require(`../../assets/images/team/${image}`)}
              alt={name}
              style={styles.image}
            />

          <ul className="social-links clearfix" style={styles.socialLinks}>
            {socials.map(({ id, icon, href }) => (
              <li key={id} style={styles.socialLinkItem}>
                <a
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.socialLinkAnchor}
                >
                  <span className={icon}></span>
                </a>
              </li>
            ))}
          </ul>

          <div className="team-description" style={styles.description}>
            <p style={styles.description2}>{description}</p>
          </div>
        </div>

        <div className="lower-box" style={styles.lowerBox}>
          <h5 style={styles.name}>
              {name}
           
          </h5>
          <div className="designation" style={styles.designation}>
            {designation}
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(SingleTeam);
