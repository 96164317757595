import React from "react";
import { Row } from "react-bootstrap";
import SingleTeam from "./SingleTeam";
import { useTranslation } from "react-i18next";

const teamSectionData = {
  teams: [
    {
      id: 1,
      image: "jean.jpeg",
      nameKey: "Jean Brunet",
      designationKey: "team.members.0.designation",
      description: "team.members.0.description",
      socials: [
        {
          id: 1,
          icon: "fab fa-linkedin",
          href: "https://www.linkedin.com/in/jean-brunet26/",
        },
      ],
    },
    {
      id: 5,
      image: "anissa.jpeg",
      nameKey: "Anissa Ben Hamza",
      description: "team.members.4.description",

      designationKey: "team.members.4.designation",
      socials: [
        {
          id: 1,
          icon: "fab fa-linkedin",
          href: "https://www.linkedin.com/in/anissa-ben-hamza-7627621bb/",
        },
      ],
    },
    
    {
      id: 4,
      image: "selim.jpeg",
      nameKey: "Selim Loued",
      description: "team.members.3.description",

      designationKey: "team.members.3.designation",
      socials: [
        {
          id: 1,
          icon: "fab fa-linkedin",
          href: "https://www.linkedin.com/in/selim-loued-69a009100/",
        },
      ],
    },
    {
      id: 6,
      image: "mariem.png",
      nameKey: "Mariem Raddaoui",
      description: "team.members.5.description",

      designationKey: "team.members.5.designation",
      socials: [
        {
          id: 1,
          icon: "fab fa-linkedin",
          href: "https://www.linkedin.com/in/mariem-raddaoui/",
        },
      ],
    },
    
    {
      id: 3,
      image: "robert.jpeg",
      nameKey: "Robert Galbraith",
      designationKey: "team.members.2.designation",
      description: "team.members.2.description",

      socials: [
        {
          id: 1,
          icon: "fab fa-linkedin",
          href: "https://www.linkedin.com/in/robert-galbraith/",
        },
      ],
    },
    {
      id: 2,
      image: "martine.png",
      nameKey: "Martine Mastio",
      designationKey: "team.members.1.designation",
            description: "team.members.1.description",
                  socials: [
        {
          id: 1,
          icon: "fab fa-linkedin",
          href: "https://www.linkedin.com/in/martine-mastio/",
        },
      ],
    },
   
   
    
  ],
  boardMembers : [
    {
      id: 1,
      image: "anis.jpeg",
      nameKey: "Anis Matoussi",
      designationKey: "board.members.0.designation",
      description: "board.members.0.description",
      socials: [
        {
          id: 1,
          icon: "fab fa-linkedin",
          href: "https://www.linkedin.com/in/anis-matoussi-aa74043a/",
        },
      ],},
      {
        id: 3,
        image: "yesim.jpeg",
        nameKey: "Yeşim Bostancı",
        designationKey: "board.members.2.designation",
        description: "board.members.2.description",
        socials: [
          {
            id: 1,
            icon: "fab fa-linkedin",
            href: "https://www.linkedin.com/in/yesim-bostanci-84a091178/",
          },
        ],},
      {
      id: 2,
      image: "arnaud.jpeg",
      nameKey: "Arnaud Mignan",
      designationKey: "board.members.1.designation",
      description: "board.members.1.description",
      socials: [
        {
          id: 1,
          icon: "fab fa-linkedin",
          href: "https://www.linkedin.com/in/amignan/",
        },
      ],
     
    
    },
    


]};


const TeamSection = () => {
  const { t } = useTranslation();
  const { teams , boardMembers } = teamSectionData;

  return (
    <section className="team-section" id="team">
      <div className="auto-container">
        <div className="sec-title centered">
          <h2>
            {t("team.title")} <span className="dot">.</span>
          </h2>
        </div>
        <Row className="clearfix">
          {teams.map((team) => (
            <SingleTeam
              key={team.id}
              team={{
                ...team,
                name: t(team.nameKey),
                designation: t(team.designationKey),
                description: t(team.description)

              }}
              className="col-lg-4 col-md-6 col-sm-12"
            />
          ))}
        </Row>

        <div className="sec-title centered" style={{ marginTop: "50px" }}>
          <h2>
            {t("board.title")} <span className="dot">.</span>
          </h2>
        </div>
        <p style={{ textAlign: "center", marginBottom: "30px" }}>
          {t("board.description")}
        </p>
        <Row className="clearfix">
  {boardMembers.map((team, index) => (
    <SingleTeam
      key={team.id}
      team={{
        ...team,
        name: t(team.nameKey),
        designation: t(team.designationKey),
        description: t(team.description),
      }}
      className="col-lg-4 col-md-6 col-sm-12"
      isBoardMember={true} // Mark as board member
      index={index} // Pass index for logic
    />
  ))}
</Row>

      </div>
    </section>
  );
};

export default TeamSection;
