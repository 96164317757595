import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const DynamicTitle = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const getTitle = () => {
    switch (location.pathname) {
      case '/':
        return t('title.home');
      case '/about-us':
        return t('title.about');
      case '/join-us':
        return t('title.join');
      case '/services':
        return t('title.services');
      case '/claims':
        return t('title.claims');
      case '/data-policy':
        return t('data-policy');
      case '/legal-notice':
        return t('legalNotice.title');
        case '/captive':
        return "Captive";
      default:
        return t('title.home');
    }
  };

  return (
    <Helmet>
      <title>Tendanz | {getTitle()}</title>
    </Helmet>
  );
};

export default DynamicTitle;
