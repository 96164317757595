import React from 'react';

const SectionWithImageAndText = ({ id, title, image, description, bulletPoints, reverse = false }) => {
  return (
    <section id={id} className={`section-with-image-text ${reverse ? 'reverse' : ''}`}>
      <div className="image">
        <img src={image} alt={title} />
      </div>
      <div className="content">
        <h2>{title}</h2>
        <p>{description}</p>
        <ul>
          {bulletPoints.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SectionWithImageAndText;
