import React from 'react';
import image from "../../assets/images/team/robert.jpeg";
const WelcomeSection = () => {
  return (
    <section id="welcome" className="welcome-section">
      <div className="profile">
      <img src={image} alt="Robert" className="profile-img" />
      <h2>Robert Galbraith</h2>
        <p>Captive Lead</p>
      </div>
      <div className="welcome-text">
        <p>
          “At Tendanz Group, our Actuarial and Data Captives Services combine in-depth actuarial
          expertise with advanced data analytics to help clients optimize their captive insurance
          strategies. We understand that managing a captive goes beyond simple risk transfer—it
          involves rigorous risk assessment, efficient capital utilization, and a proactive approach
          to regulatory compliance. Our team collaborates closely with clients to design tailored
          solutions that address their specific risk profiles, ensuring that their captive is a
          powerful tool for long-term stability and growth.”
        </p>
      </div>
    </section>
  );
};

export default WelcomeSection;
