import React from 'react';
import Sidebar from '../components/Captive/Sidebar';
import WelcomeSection from '../components/Captive/WelcomeSection';
import SectionWithImageAndText from '../components/Captive/SectionWithImageAndText';
import TestimonialsSection from '../components/Captive/TestimonialsSection';
import '../styles/captivepage.css'
import ai from "../assets/images/captive/ai.jpg"
import data from "../assets/images/captive/data.jpg"
import management from "../assets/images/captive/management.jpg"
import risk from "../assets/images/captive/risk.jpg"
import frank from "../assets/images/captive/frank.jpg"
import santiago from "../assets/images/captive/santiago.jpg"



const CaptivePage = () => {
  const navItems = [
    { id: 'welcome', name: 'Welcome' },
    { id: 'actuarial', name: 'Actuarial and Risk Services' },
    { id: 'transformation', name: 'Transformation Management' },
    { id: 'data', name: 'Data Management' },
    { id: 'ai', name: 'AI Technology' },
    { id: 'testimonials', name: 'Testimonials' },
  ];

  const onNavClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  

  const testimonials = [
    {
      image: santiago,
      name: 'Santiago Gomez',
      job: "Head Officer at PEMEX's Captive (re-)Insurance Company. Member of the Executive Board. MScIR and MSceB. ",
      description:
        "“Jean Brunet [founder of Tendanz] has been a key asset for Pemex's captive since 2018, where he serves as a Senior Actuary. His deep expertise in automating complex capital simulations has streamlined processes and improved efficiency. Jean has also created a centralized claims database, providing a single source of truth that significantly enhances data accuracy and accessibility. Always available to offer valuable actuarial advice, Jean is not only an expert in his field but also a dedicated professional who consistently goes above and beyond to support the team. His proactive approach and commitment to excellence make him a reliable partner for any actuarial needs.”",
    },
    {
        image:  frank,
        name: 'Franck Baron',
      job: 'Group Deputy Director at International SOS',
      description:
        '“International SOS has engaged Tendanz to perform an ORSA on its captive. I was pleasantly surprised on how fast Tendanz was able to understand the technical aspects of the business, and how they were able to fit the business in the overall business environment. I would definitely recommend Tendanz for any captives who are thinking of performing an ORSA for their own businesses.” ',
    },
  ];

  return (
    <div className="captive-page">
      <Sidebar navItems={navItems} onNavClick={onNavClick} />
      <div className="main-content">
      <div className="page-title">
          <h1>Captive Solutions</h1>
        </div>
        <WelcomeSection />
        <SectionWithImageAndText
  id="actuarial"
  title="Actuarial and Risk Services"
  image={risk}
  description="We offer a full array of actuarial services to captives across all geographies, ranging from capital modelling over pricing and reserving to feasibility studies and independent reviews:"
  bulletPoints={[
    'Solvency II and SST calculations, including ORSA and actuarial report',
    'Actuarial Function and Responsible Actuary mandates',
    'Loss reserve / IBN(e)R calculations',
    'Pricing and premium allocation',
    'Loss modelling, retention optimization, capital impact analysis...',
    'Independent actuarial reviews',
  ]}
  reverse={true} 
/>

<SectionWithImageAndText
  id="transformation"
  title="Transformation Management"
  image={management}
  description="We can help you streamline your captive’s processes in order to save costs, for example with the following topics:"
  bulletPoints={[
    'Simplification of communication streams...',
    'Outsourcing of non-core activities',
    'Transformation of actuarial, finance and claims departments using data insights',
  ]}
  
/>

<SectionWithImageAndText
  id="data"
  title="Data Management"
  image={data}
  description="We offer a range of services around data topics, ranging from improving data quality to visualization:"
  bulletPoints={[
    'Data governance such as GDPR compliance...',
    'Data architecture - design and set up of databases',
    'Data flow management and reporting automation',
    'Customized data visualization',
  ]}
  reverse={true} 
/>

<SectionWithImageAndText
  id="ai"
  title="AI Technology"
  image={ai}
  description="We use AI technology for example in the context of:"
  bulletPoints={[
    'Natural language processing tools',
    'Set up of internal chatbots',
    'Reporting automation',
  ]}
/>

        <TestimonialsSection testimonials={testimonials} />
      </div>
    </div>
  );
};

export default CaptivePage;
