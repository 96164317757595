import React from 'react';
import logo1 from '../../assets/images/logo-horis.png';
import { Link } from 'react-router-dom';

const Sidebar = ({ navItems, onNavClick }) => {
  return (
    <aside className="sidebar">
      <div className="sidebar-logo">
        <Link
          to="/"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
        >
          <img src={logo1} alt="Tendanz Logo" />
        </Link>
      </div>
      <ul className="sidebar-nav">
        {navItems.map((item) => (
          <li key={item.id}>
            <a
              href={`#${item.id}`}
              onClick={(e) => {
                e.preventDefault();
                onNavClick(item.id);
              }}
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
