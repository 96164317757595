import React from 'react';

const TestimonialsSection = ({ testimonials }) => {
  return (
    <section id="testimonials" className="testimonials-section">
      <h2>Testimonials</h2>
      <div className="testimonials-container">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <img src={testimonial.image} alt={testimonial.name} />
            <h3>{testimonial.name}</h3>
            <p className="job">{testimonial.job}</p>
            <p className="description">{testimonial.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialsSection;
